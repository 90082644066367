<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_password_manager')}}</b-breadcrumb-item>
      </div>
    </div>


      <b-card no-body>
          <b-card-header class="pb-50">
              <h5 class="text-primary">
                  {{$t('label_password_manager')}}
                  <!--{{ // $t('label_filters') }}-->
              </h5>
          </b-card-header>
          <b-card-body class="pb-0">
              <b-row>

                  <b-col
                          cols="12"
                          md="12"
                          class="mb-md-0 mb-2 d-flex align-items-center"
                  >


                      <b-button variant="outline-primary"
                                v-if="$can('edit', PREFIX+'.categories')"
                                @click="$bvModal.show(CATEGORY_PREFIX+'-modal')"
                                class="mr-1 ">
                          <feather-icon
                                  icon="CheckIcon"
                                  class="mr-50"
                          />
                          <span>{{$t('label_category_add')}}</span>
                      </b-button>

                      <b-button @click="directHttp('GET', $base_url + MODULE_PREFIX + '_export' + filterData.objectToQueryString(true), formatDate(new Date().getTime()/1000, 'DD-MM-YYYY', 'unix') + '_export.xls' )" variant="outline-primary"
                                 v-if="$can('edit', PREFIX+'.tab_details')" class="mr-1" >
                          <feather-icon
                                  icon="DownloadIcon"
                                  class="mr-50"
                          />
                          <!--<img height="22px" :src="require('@/assets/images/icons/xls.png')" alt="">-->
                          <span>{{$t('label_export')}}</span>
                      </b-button>

                  </b-col>

                  <b-col cols="12">
                      <b-row class="mt-2 mb-1">

                          <b-col cols="12" md="3" class="mb-2">
                              <label>{{$t('label_category')}}</label>
                              <v-select
                                      v-model="filterData.category_id"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="categories"
                                      :reduce="val => val.id"
                                      label="idCountry"
                                      :clearable="false"
                                      :placeholder="$t('label_select')"
                              >
                                  <template v-slot:option="option">
                                      {{option.name}}
                                  </template>
                                  <template v-slot:selected-option="option">
                                      {{option.name}}
                                  </template>
                                  <template v-slot:no-options="option">
                                      {{$t('label_no_items')}}
                                  </template>
                              </v-select>
                          </b-col>
                          <b-col cols="12" md="3"  class="mb-2">
                              <label>{{$t('label_user')}}</label>
                              <v-select
                                      style="max-width: 300px"
                                      class="sort--select"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      v-model="filterData.user_id"
                                      :options="users"
                                      label="name"
                                      :placeholder="$t('label_user')"
                                      :reduce="val => val.id"

                              >
                                  <template v-slot:option="option">
                                      <!--<span :class="option.icon"></span>-->
                                      <span class="">{{ option.name }}</span>
                                  </template>
                                  <template v-slot:selected-option="option">
                                      <!--<span :class="option.icon"></span>-->
                                      <span >{{ option.name}}</span>
                                  </template>
                                  <template v-slot:no-options="option">
                                      {{$t('label_no_items')}}
                                  </template>
                              </v-select>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-card-body>
      </b-card>

    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

          >
            <!--<label>{{ $t('label_show') }}</label>-->
              <v-select
                      v-model="tableData.perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tableData.perPageOptions"
                      :clearable="false"
                      :searchable="false"
                      class="per-page-selector d-inline-block mr-1"
              >
                  <template v-slot:no-options="option">
                      {{$t('label_no_items')}}
                  </template>
              </v-select>

              <column-chooser v-model="selectedColumns" :not-touch="['id']"
                              :columns="columnsDefs"></column-chooser>

          </b-col>

            <b-col
                    cols="12"
                    md="5"
                    class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >

                <b-form-input
                        v-model="tableData.searchQuery"
                        type="search"
                        debounce="500"
                        class="d-inline-block mr-1 "
                        :placeholder="$t('label_search')+'...'"
                />

                <b-button
                        v-if="$can('edit', this.PREFIX+'.tab_details')"
                        @click="editedItem = false; $bvModal.show(MODULE_PREFIX+'-modal')"
                        variant="primary"
                >
                    <span class="text-nowrap">{{ $t('label_add') }}</span>
                </b-button>
            </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

     <div class="px-2 table-container-wrap">
       <!--sticky-header-->
       <b-table
               ref="table"
               class="position-relative transited-table"
               :busy="isBusy"
               no-border-collapse
               :items="tableItems"
               responsive
               :fields="selectedColumns"
               primary-key="id"
               :sort-by.sync="tableData.sortBy"
               no-local-sorting
               :sort-desc.sync="tableData.sortDesc"
               show-empty
               :tbody-transition-props="{ name: 'flip-list'}"
               :empty-text="$t('label_no_table_data')"

       >

         <template #head()="data">
           <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
         </template>

         <template #cell(users)="data">
             <div>
                 <b-avatar-group
                         class="" style="padding-top: 5px;"
                         size="26px"
                 >
                     <b-avatar
                             v-for="user in data.item.users.slice(0,5)"
                             :key="user.id"
                             v-b-tooltip.hover.bottom="user.name"
                             :src="user.profile_picture? $domain + user.profile_picture : ''"
                             class="pull-up"
                             button
                             @click.prevent="resolveRouteToRedirectUser(user);"
                     />&nbsp;<h6 v-if="data.item.users.length > 5" class="align-self-center cursor-pointer ml-1 mb-0">
                     <a href="javascript:void(0)" :id="'user_level_' + data.item.id" data-popover-dismiss class="structure-more-employee">+{{data.item.users.length - 5 }}</a>
                 </h6>
                     <b-popover
                             class="bg-info"
                             :target="'user_level_' + data.item.id"
                             triggers="hover"
                             placement="top"
                             variant="secondary"
                     >
                         <template #title>
                             <span>{{$t('label_show')}}</span>
                         </template>
                         <div class="tooltip-content">
                             <div class="popover-content">
                                 <div v-for="u in data.item.users" class="structure-employee-wrap">
                                     <a href="#"  @click="resolveRouteToRedirectUser(u)">{{u.name}}</a>
                                 </div>
                             </div>
                         </div>
                     </b-popover>
                 </b-avatar-group>
             </div>
         </template>

         <!-- Column: Actions -->
         <template #cell(actions)="data">
           <b-dropdown
                   dropleft
                   boundary="window"
                   variant="link"
                   no-caret
                   @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                   @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                   :right="$store.state.appConfig.isRTL"
           >

             <template #button-content>
               <feather-icon
                       icon="MoreVerticalIcon"
                       size="16"
                       class="align-middle text-body"
               />
             </template>



             <b-dropdown-item disabled>
               <div class="divider">
                 <div class="divider-text">{{ $t('label_action') }}</div>
               </div>
             </b-dropdown-item>

             <b-dropdown-item @click="editedItem = data.item; $bvModal.show(MODULE_PREFIX + '-modal');">
               <feather-icon icon="EditIcon"/>
               <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
             </b-dropdown-item>

             <b-dropdown-item @click="deleteItem(data.item.id)">
               <feather-icon icon="TrashIcon"/>
               <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
             </b-dropdown-item>


           </b-dropdown>
         </template>

       </b-table>
       <div class="mx-2 mb-2">
         <b-row>

           <b-col
                   cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-start"
           >
             <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
           </b-col>
           <!-- Pagination -->
           <b-col
                   cols="12"
                   sm="6"
                   class="d-flex align-items-center justify-content-center justify-content-sm-end"
           >

             <b-pagination
                     v-model="tableData.currentPage"
                     :total-rows="tableTotal"
                     :per-page="tableData.perPage"
                     first-number
                     last-number
                     class="mb-0 mt-1 mt-sm-0"
                     prev-class="prev-item"
                     next-class="next-item"
             >
               <template #prev-text>
                 <feather-icon
                         icon="ChevronLeftIcon"
                         size="18"
                 />
               </template>
               <template #next-text>
                 <feather-icon
                         icon="ChevronRightIcon"
                         size="18"
                 />
               </template>
             </b-pagination>

           </b-col>

         </b-row>
       </div>
     </div>

    </b-card>


      <process-item-modal
              :categories="categories"
              :users="users"
              @item-added="refreshDataTable()"
              @item-edited="refreshDataTable()"
              :edited-item="editedItem"
      ></process-item-modal>

      <process-category-modal
              @item-added="getPageData()"
      ></process-category-modal>

  </div>
</template>

<script>
    import {
        BCard,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BPopover, BAvatarGroup,VBTooltip,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import columnChooser from '@/views/components/columnChooser'
    import infinityScroll from '@/views/components/infinityScroll'
    import processCategoryModal from './modal/processCategoryModal'
    import processItemModal from './modal/processItemModal'
    import {resolveRouteToRedirectUser} from '../pagesHelper'

    import {MODULE_PREFIX, MODULE_PREFIX as PREFIX, CATEGORY_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BPopover, BAvatarGroup,

            vSelect,
            columnChooser,
            infinityScroll,
            processCategoryModal,
            processItemModal
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                CATEGORY_PREFIX,
                PREFIX,
                MODULE_PREFIX,
                resolveRouteToRedirectUser,
                editedItem: false,

                selectedColumns:[],

                columnsDefs: [

                    {label: 'label_user', key: 'user_name', sortable: false},
                    {label: 'label_service', key: 'service', sortable: false},
                    {label: 'label_category_name', key: 'category_name', sortable: false},
                    {label: 'label_users', key: 'users', sortable: false},
                    {label: 'label_creating_date', key: 'created_at', sortable: false,
                        formatter: function (v, k, item) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss')
                        }
                    },
                    {label: 'label_date_of_update', key: 'updated_at', sortable: false,
                        formatter: function (v, k, item) {
                            return v?formatDate(v, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss') : '--'
                        }
                    },
                    {label: 'label_action', key: 'actions'},

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [ 10, 25, 50, 100],
                },
                filterData: {
                    category_id: null,
                    user_id: null,
                },
                users:[],
                categories:[],

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el)=>{
                if(el.key == 'actions' && !this.$can('edit', this.PREFIX+'.tab_details')){
                    return false;
                }
                if(el.key == 'user' && !['admin', 'superadmin'].includes(this.userRole(this.authUser()))){
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData(){

                this.async('get', '/'+this.MODULE_PREFIX+'_categories', {}, (res) => {this.categories = res.data});
                this.async('get', '/'+this.MODULE_PREFIX+'/selects', {}, (res) => {this.users = res.data.users});

            },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.PREFIX, {params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        category_id: this.filterData.category_id,
                        user_id: this.filterData.user_id,

                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/'+this.PREFIX+'/'+id, {}, function (resp) {

                        this.refreshDataTable();
                    });
                })
            },

        },

    }
</script>

