<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit'):$t('label_add')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >


                <!--<validation-provider-->
                        <!--v-if="['admin', 'superadmin'].includes(userRole(authUser()))"-->
                        <!--#default="validationProps"-->
                        <!--:name="$t('label_user')"-->
                        <!--rules=""-->
                        <!--slim-->
                <!--&gt;-->
                    <!--<b-form-group-->
                            <!--:label="$t('label_user')"-->
                    <!--&gt;-->
                    <!---->
                        <!--<infinity-scroll-->
                                <!--:query-params="{expectUsersIds : [1]}"-->
                                <!--selected-prop="id"-->
                                <!--:multiple="false"-->
                                <!--url="/user"-->
                                <!--:placeholder="$t('label_select')"-->
                                <!--v-model="itemData.user_id"-->
                                <!--:close-on-select="false"-->
                                <!--:default-selection="chosenUser"-->

                        <!--&gt;-->
                            <!--&lt;!&ndash;:default-selection="chosenRelDocs"&ndash;&gt;-->
                            <!--<template #label="{item}">{{ item.name }}</template>-->

                        <!--</infinity-scroll>-->
                        <!--<b-form-invalid-feedback :state="getValidationState(validationProps)">-->
                            <!--{{ validationProps.errors[0] }}-->
                        <!--</b-form-invalid-feedback>-->
                    <!--</b-form-group>-->
                <!--</validation-provider>-->

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_users')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_users')"
                    >
                        <!--:query-params="{extra_search:'default'}"-->
                        <!--:default-selection="chosenRelDocs"-->
                        <infinity-scroll
                                selected-prop="id"
                                :multiple="true"
                                url="/user"
                                :placeholder="$t('label_select')"
                                :close-on-select="false"
                                v-model="itemData.related_users"
                                :default-selection="chosenRelUsers"
                        >
                            <template #label="{item}">{{ item.name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_category')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_category')"

                    >
                        <v-select
                                v-model="itemData.category_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="categories"
                                :reduce="val => val.id"
                                :clearable="true"
                                label="id"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <span class="">{{ $t(option.name) }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{ $t(option.name)}}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_service')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_service')"

                    >
                        <b-form-input

                                v-model="itemData.service"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_service')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_username')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_username')"

                    >
                        <b-form-input

                                v-model="itemData.user_name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_username')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_password')"
                        :rules="action=='adding'? 'required' : ''"
                        slim
                >
                    <b-form-group
                            :label="$t('label_password')"
                            ref="password_input"
                    >
                        <b-input-group>
                        <b-form-input
                                v-show="showRealPassword || action == 'adding'"
                                :type="passInputType"
                                ref="real_password_input"
                                v-model="itemData.password"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_password')"
                        />
                            <!--@input="itemData.password = $event;showRealPassword = true;$refs['real_password_input'].focus()"-->
                            <b-form-input
                                    :disabled="true"
                                    @focus="showRealPassword = true"
                                    v-show="!showRealPassword && action != 'adding'"
                                    ref="fake_password_input"
                                   type="password"
                                    v-model="fake_password"

                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_password')"

                            />
                        <b-input-group-append cass="cursor-pointer" is-text>
                            <!--<feather-icon-->
                                    <!--@click="toggleShowingPassword(itemData); showRealPassword = !showRealPassword "-->
                                    <!--icon="EditIcon"-->
                                    <!--class="cursor-pointer mr-1"-->
                            <!--/>-->
                            <!--|-->
                            <feather-icon
                                    @click="toggleShowingPassword(itemData)"
                                    :icon="((action == 'editing' && showRealPassword) ||  (action == 'adding' && passInputType == 'password')) ? 'EyeOffIcon' : 'EyeIcon'"
                                    class="cursor-pointer  mr-1"
                            />
                            |
                            <feather-icon
                                    @click="copyPassword()"
                                    icon="CopyIcon"
                                    class="cursor-pointer ml-1"

                            />

                        </b-input-group-append>
                            </b-input-group>
                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>



                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary">{{(action == 'editing')?$t('label_edit'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import vSelect from 'vue-select'
    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend,
        BButton,
    } from 'bootstrap-vue'
    import  infinityScroll from '@/views/components/infinityScroll'
    import { MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll
        },
        props: ['editedItem', 'categories'],
        data() {
            return {

                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    category_id: '',
                    // user_id: '',
                    service: '',
                    user_name: '',
                    password:null,
                    related_users: [],
                },
                itemData: {},
                showRealPassword: false,
                // passwordIsRequired: false,
                fake_password: '***',
                // passwordFieldType: 'password',
                chosenRelUsers: [],
                passInputType: 'text'
            }
        },

        methods: {
            initData() {
                this.showRealPassword = false;
                let item = Object.assign({}, this.blankItemData);

                if (this.editedItem) {

                    this.action = 'editing';

                    item.id = this.editedItem.id;
                    // console.log(this.editedItem);
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.password = null;
                    if(this.editedItem.users) {
                        item.related_users = this.editedItem.users.pluck('id');
                        this.chosenRelUsers = this.editedItem.users;
                    }

                } else {
                    this.action = 'adding';
                    this.passInputType = 'password';
                    this.fake_password = '';
                    this.fake_password = '***';
                    this.showRealPassword = false;
                }

                this.itemData = item;

            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.passInputType = 'text';
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData =  Object.assign({}, this.itemData);
                if(!formData.password) delete formData.password;

                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX , this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                }

            },
            copyPasswordText(text){
                let self = this;
                this.$copyText(text, this.$refs['password_input'].$el).then(() => {
                    self.$toast.info(this.$t('text_copied'));
                }, () => {

                })
            },
            copyPassword(){
                // this.showRealPassword = true;
                if(this.itemData.id > 0 && !this.itemData.password){
                    this.getPassword(this.itemData.id, true);
                } else {
                    this.copyPasswordText(this.itemData.password);
                }
            },

            toggleShowingPassword(item){

                if(this.action == 'editing') {
                    this.showRealPassword = !this.showRealPassword;
                    if(this.showRealPassword) {
                        let self = this;
                        if(this.itemData.id > 0 && !this.itemData.password){
                            this.getPassword(this.itemData.id);
                        }
                    }
                } else {
                    this.passInputType = this.passInputType == 'text'? 'password' : 'text';
                }

            },
            getPassword(item_id, copy){
                this.async('get', '/' + this.MODULE_PREFIX + '/' + item_id, {}, function (resp) {
                    this.itemData.password = resp.data.password;

                    if(copy)
                    this.copyPasswordText(resp.data.password);
                });
            }
        },

    }
</script>